/**
 * Creates a script and injects it into the DOM body.
 * @param url
 * @param {number} [maxWait=3000] - How long to wait for the promise to resolve (in milliseconds) before rejecting.
 * @returns {Promise}
 */
const createScript = (url, maxWait = 3000) =>
  new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.src = url;
    script.onerror = reject;
    script.onload = resolve;
    document.body.appendChild(script);
    window.setTimeout(reject, maxWait);
  });

export default {
  createScript,
};
