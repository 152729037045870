import ccUIReducer from 'modules/cc-ui/cc-ui-slice';
import cogReducer from 'modules/cog/cog-slice';
import errorsReducer from 'modules/error/error-slice';
import globalWrapperReducer from 'modules/global-wrapper/global-wrapper-slice';
import paymentMethodsReducer from 'modules/payment-methods/payment-methods-slice';
import sessionDetailsReducer from 'modules/session-details/session-details-slice';
import transactionsCcReducer from 'modules/transactions-cc/transactions-cc-slice';
import ucsReducer from 'modules/ucs/ucs-slice';
import { CCUIState } from 'types/cc-ui';
import { COGState } from 'types/cog';
import { GlobalWrapperState } from 'types/global-wrapper';
import { PaymentMethodsState } from 'types/payment-method';
import { SessionDetailsState } from 'types/session-details';
import { TransactionsCcState } from 'types/transactions-cc';
import { UCSState } from 'types/ucs';

export interface RootReducer {
  globalWrapperReducer: GlobalWrapperState;
  sessionDetailsReducer: SessionDetailsState;
  paymentMethodsReducer: PaymentMethodsState;
  transactionsCcReducer: TransactionsCcState;
  ccUIReducer: CCUIState;
  ucsReducer: UCSState;
  cogReducer: COGState;
}
const rootReducer = {
  globalWrapper: globalWrapperReducer,
  sessionDetails: sessionDetailsReducer,
  paymentMethods: paymentMethodsReducer,
  transactionsCc: transactionsCcReducer,
  errors: errorsReducer,
  ccUI: ccUIReducer,
  ucs: ucsReducer,
  cog: cogReducer,
};

export default rootReducer;
