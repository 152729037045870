import { FC, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { ChevronLeftOutlinedIcon, GotoAdminPartnerFaviconIcon } from '@getgo/chameleon-icons/react';
import { Button, GotoLogo, Typography } from '@getgo/chameleon-web-react-wrapper';

import PriceInfo from 'components/price-info';
import SessionExpiredModal from 'components/session-expired-modal';
import SessionTimer from 'components/session-timer';
import { useAppSelector } from 'hooks';
import { accountKey } from 'modules/global-wrapper';
import { isSessionExpired, sesssionCancelRedirectUrl } from 'modules/session-details';
import Track, { PaynowBackToCheckout, PaynowLanding } from 'modules/tracking';
import { CREDIT_CARD } from 'utils/constants';
import st from 'utils/shared-translations';
import CreditCardPay from './credit-card-pay';

import './pay-now.css';

interface PaymentMethodsProps {
  paymentMethodType: string;
}

const PayNow: FC<PaymentMethodsProps> = ({ paymentMethodType }) => {
  const intl = useIntl();

  const selectedAccountKey = useAppSelector(accountKey);
  const selectedSessionIsExpired = useAppSelector(isSessionExpired);
  const selectedCancelRedirectUrl = useAppSelector(sesssionCancelRedirectUrl);

  useEffect(() => {
    if (selectedAccountKey) {
      Track(PaynowLanding, {});
    }
  }, [selectedAccountKey]);

  const redirectToCheckout = () => {
    Track(PaynowBackToCheckout, {});
    window.location.assign(selectedCancelRedirectUrl);
  };

  return (
    <div className="pay-now">
      {selectedSessionIsExpired && <SessionExpiredModal redirectType="Checkout" redirectMethod={redirectToCheckout} />}
      <nav className="pay-now__nav">
        <Button size="medium" variant="neutral" leadingIcon={<ChevronLeftOutlinedIcon />} onClick={redirectToCheckout}>
          {intl.formatMessage(st['back.to.previous.page'])}
        </Button>
      </nav>
      <header className="pay-now__header">
        <SessionTimer />
        <GotoLogo>
          <GotoAdminPartnerFaviconIcon />
        </GotoLogo>
      </header>
      <aside className="pay-now__aside">
        <PriceInfo />
      </aside>
      <main className="pay-now__main">
        {
          {
            [CREDIT_CARD]: <CreditCardPay />,
          }[paymentMethodType]
        }
      </main>
      <footer className="pay-now__footer">
        <Typography variant="caption-medium" color="type-color-secondary">
          <FormattedMessage
            {...st['payment.tnc']}
            values={{
              // @ts-ignore
              'tos-link': (msg: string) => (
                <a
                  href="https://www.goto.com/company/legal/terms-and-conditions"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Typography tag="span" variant="body-small" color="link">
                    {msg}
                  </Typography>
                </a>
              ),
              // @ts-ignore
              'privacy-policy': (msg: string) => (
                <a href="https://www.goto.com/company/legal/privacy" target="_blank" rel="noopener noreferrer">
                  <Typography tag="span" variant="body-small" color="link">
                    {msg}
                  </Typography>
                </a>
              ),
              // @ts-ignore
              'anti-spam': (msg: string) => (
                <a href="https://www.goto.com/company/legal/anti-spam-policy" target="_blank" rel="noopener noreferrer">
                  <Typography tag="span" variant="body-small" color="link">
                    {msg}
                  </Typography>
                </a>
              ),
            }}
          />
        </Typography>
      </footer>
    </div>
  );
};

export default PayNow;
