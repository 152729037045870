import { FC, useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useLifecycles } from 'react-use';
import { Skeleton, Typography } from '@getgo/chameleon-web-react-wrapper';

import { useAppSelector } from 'hooks';
import { FlexMicroformWrapper } from 'lib/cybersource-flex-microform';
import { isCCLoading, isCVVValid, setCVVValidity } from 'modules/cc-ui';
import { transactionsCcIsLoading } from 'modules/transactions-cc';
import { CREDIT_CARD_CVV } from 'utils/constants';
import st from 'utils/shared-translations';

import './credit-card-cvv.css';

const FORM_FIELD_CVV_ID = 'cvv';

let cvvFieldInstance: any = null;

const cvvStyle = {
  styles: {
    input: {
      'font-size': '14px',
    },
  },
};

interface CreditCardCvvProps {
  FlexMicroform: FlexMicroformWrapper;
  formValues: any;
  setFormValues: any;
  isMicroformReady: boolean;
}

const CreditCardCvv: FC<CreditCardCvvProps> = ({
  FlexMicroform,
  formValues,
  setFormValues,
  isMicroformReady,
}): JSX.Element => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const [isCvvErrorMessage, setCvvErrorMessage] = useState(false);
  const [isCvvFieldFocus, setCvvFieldFocus] = useState(false);

  const selectedTransactionCcLoading = useAppSelector(transactionsCcIsLoading);

  const selectedCCIsLoading = useAppSelector(isCCLoading);
  const selectedCVVIsValid = useAppSelector(isCVVValid);

  /**
   * Mount and Unmount
   * Dispose cvv instances
   */
  useLifecycles(
    () => {},
    () => {
      cvvFieldInstance && cvvFieldInstance.dispose();
      cvvFieldInstance = null;
    },
  );

  /**
   * Handles changes made on CVV field
   */
  const cvvChangeHandler = useCallback(
    (data) => {
      if (data.empty) {
        setFormValues((prevFormValues) => ({ ...prevFormValues, [CREDIT_CARD_CVV]: '' }));
      } else {
        setFormValues((prevFormValues) => ({ ...prevFormValues, [CREDIT_CARD_CVV]: 'notEmpty' }));
      }

      dispatch(setCVVValidity(data.valid));
    },
    [setFormValues, dispatch],
  );

  /**
   * Create Flex form instance of CVV number and Add events on it.
   */
  useEffect(() => {
    if (isMicroformReady && !cvvFieldInstance) {
      cvvFieldInstance = FlexMicroform.createFieldInstance(CREDIT_CARD_CVV, cvvStyle);

      cvvFieldInstance.on('change', (data) => cvvChangeHandler(data));
      cvvFieldInstance.on('focus', () => setCvvFieldFocus(true));
      cvvFieldInstance.on('blur', () => {
        setCvvErrorMessage(!selectedCVVIsValid);
        setCvvFieldFocus(false);
      });

      cvvFieldInstance.load(`#${FORM_FIELD_CVV_ID}`);
    }
  }, [FlexMicroform, cvvChangeHandler, selectedCVVIsValid, isMicroformReady]);

  const isCvvInvalidMessage =
    isCvvErrorMessage && !isCvvFieldFocus && !selectedCVVIsValid && !!formValues[CREDIT_CARD_CVV];

  const getCvvClass = () => {
    if (isCvvInvalidMessage) {
      return 'credit-card-cvv__error';
    } else if (isCvvFieldFocus) {
      return 'credit-card-cvv__focused';
    }
    return 'credit-card-cvv__field';
  };

  const isLoading = selectedTransactionCcLoading || selectedCCIsLoading;

  return (
    <section className="credit-card-cvv">
      <Typography
        tag="label"
        className="credit-card-cvv__label"
        variant="caption-medium-strong"
        color="type-color-default"
      >
        {intl.formatMessage(st['creditcardform.paymentdetails.cvv'])}
      </Typography>
      {isLoading ? (
        <Skeleton size="large" variant="rectangle" />
      ) : (
        <>
          <input name={CREDIT_CARD_CVV} defaultValue={formValues[CREDIT_CARD_CVV]} type="text" hidden />
          <div id={FORM_FIELD_CVV_ID} className={getCvvClass()} />
        </>
      )}
      <>
        {isCvvInvalidMessage ? (
          <Typography variant="caption-medium" tag="p" color="type-color-danger">
            {intl.formatMessage(st['creditcardform.cvv.validation.errormessage'])}
          </Typography>
        ) : (
          <Typography variant="caption-medium" tag="p" color="type-color-secondary">
            {intl.formatMessage(st['creditcardform.paymentdetails.cvv.message'])}
          </Typography>
        )}
      </>
    </section>
  );
};

export default CreditCardCvv;
