import { FC, useState } from 'react';
import { useIntl } from 'react-intl';
import { TrashOutlinedIcon } from '@getgo/chameleon-icons/react';
import { ChipV2, IconButton, Typography } from '@getgo/chameleon-web-react-wrapper';

import { useAppDispatch, useAppSelector } from 'hooks';
import { showErrorSnack } from 'modules/error';
import { partnerType } from 'modules/global-wrapper';
import { postPaymentMethods, postPaymentMethodsDelete, postPaymentMethodsSetDefault } from 'modules/payment-methods';
import { CreditCard } from 'types/payment-method';
import { getCardInfo } from 'utils/credit-card-utils';
import st from 'utils/shared-translations';

import './card-info.css';

interface CardInfoProps {
  cardDetails: CreditCard;
}

const CardInfo: FC<CardInfoProps> = ({ cardDetails }): JSX.Element => {
  const intl = useIntl();
  const dispatch = useAppDispatch();

  const { type, last4Digits, expirationMonth, expirationYear } = cardDetails;

  const cardInfo = getCardInfo(type.toUpperCase(), intl);

  const [isAPILoading, setAPILoading] = useState(false);

  const selectedPartnerType = useAppSelector(partnerType);

  /**
   * This method handles both API call (delete and set as default)
   * based on the passed action.
   */
  const handleAPICall = (action: Function) => {
    const payload = { paymentMethodKey: cardDetails?.paymentMethodKey };
    setAPILoading(true);
    dispatch(action(payload))
      .unwrap()
      .then(() => {
        setAPILoading(false);
        dispatch(postPaymentMethods());
      })
      .catch(() => {
        setAPILoading(false);
        dispatch(showErrorSnack(st['alert.error.general.refreshtryagain']));
      });
  };

  return (
    <div className="card-info">
      <section>
        <div className="card-info__icon-num">
          {cardInfo.cardIcon}
          <Typography variant="body-small">
            {cardInfo.ccDigitLength === 15 ? <>**** ****** *{last4Digits}</> : <>**** **** **** {last4Digits}</>}
          </Typography>
        </div>
        <Typography
          variant="caption-small"
          color="type-color-secondary"
        >{`${expirationMonth} / ${expirationYear}`}</Typography>
      </section>
      {cardDetails?.isDefault ? (
        <ChipV2 aria-label="primary card" className="card-info__primary" size="small">
          <Typography
            tag="span"
            className="card-info__chip-text"
            variant="caption-medium-strong"
            color="type-color-inverted"
          >
            {intl.formatMessage(st['payment.methods.default'])}
          </Typography>
        </ChipV2>
      ) : (
        <div className="card-info__ctas">
          <ChipV2
            type="button"
            size="small"
            aria-label="set as primary card button"
            disabled={isAPILoading || selectedPartnerType === 'PARENT'}
            onAction={() => handleAPICall(postPaymentMethodsSetDefault)}
          >
            <Typography tag="span" className="card-info__chip-text" variant="caption-medium-strong">
              {intl.formatMessage(st['payment.methods.cta.setdefault'])}
            </Typography>
          </ChipV2>
          <IconButton
            disabled={isAPILoading || selectedPartnerType === 'PARENT'}
            size="medium"
            variant="secondary"
            label="delete card"
            onClick={() => handleAPICall(postPaymentMethodsDelete)}
          >
            <TrashOutlinedIcon />
          </IconButton>
        </div>
      )}
    </div>
  );
};

export default CardInfo;
