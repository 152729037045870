// OnePay events
export const OnePayLanding = 'OnePayLanding';
export const OnePayAPIError = 'OnePayAPIError';

// Payment methods events
export const PaymentMethodLanding = 'PaymentMethodLanding';
export const PaymentMethodBackToG2A = 'PaymentMethodBackToG2A';

// Credit card events
export const CreditCardSaveCTA = 'CreditCardSaveCTA';
export const CreditCardCancelCTA = 'CreditCardCancelCTA';
export const CreditCardDefaultSet = 'CreditCardDefaultSet';

// Paynow events
export const PaynowLanding = 'PaynowLanding';
export const PaynowBackToCheckout = 'PaynowBackToCheckout';
export const PaynowUseCC = 'PaynowUseCC';
export const PaynowCTA = 'PaynowCTA';
export const PaynowSaveAndPay = 'PaynowSaveAndPay';

export const Revenue = 'Revenue';
