import { FC } from 'react';
import { Route, Routes } from 'react-router-dom';

import ErrorSnackbar from 'components/error-snackbar';
import PaymentFailure from 'components/feedback/payment-failure';
import PaymentSuccess from 'components/feedback/payment-success';
import ProcessQuoteLoader from 'components/process-quote-loader';
import PayNow from 'routes/pay-now';
import PaymentMethods from 'routes/payment-methods';
import { CREDIT_CARD } from 'utils/constants';

const CoreView: FC = (): JSX.Element => (
  <>
    <ErrorSnackbar />
    <PaymentSuccess />
    <PaymentFailure />
    <ProcessQuoteLoader />
    <Routes>
      <Route
        path="/:accountKey/:sessionId/paymentmethod/cc"
        element={<PaymentMethods paymentMethodType={CREDIT_CARD} />}
      />
      <Route path="/:accountKey/:sessionId/pay/cc" element={<PayNow paymentMethodType={CREDIT_CARD} />} />
    </Routes>
  </>
);
export default CoreView;
