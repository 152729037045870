import { FC } from 'react';

import CreditCardForm from 'components/credit-card-form';
import CreditCardHeader from 'components/credit-card-header';
import { CREDIT_CARD_SAVE } from 'utils/constants';

const CreditCard: FC = (): JSX.Element => (
  <>
    <CreditCardHeader />
    <CreditCardForm intent={CREDIT_CARD_SAVE} />
  </>
);

export default CreditCard;
