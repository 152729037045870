import { FC, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { RadioButton, RadioGroup } from '@getgo/chameleon-web-react-wrapper';

import BuyNow from 'components/buy-now';
import CardInfo from 'components/card-info';
import CreditCardForm from 'components/credit-card-form';
import CreditCardHeader from 'components/credit-card-header';
import { useAppSelector } from 'hooks';
import { partnerType } from 'modules/global-wrapper';
import { creditCardList, defaultCreditCard, paymentMethodLimits } from 'modules/payment-methods';
import Track, { PaynowUseCC } from 'modules/tracking';
import { CreditCard } from 'types/payment-method';
import { CREDIT_CARD_ADD_PAY } from 'utils/constants';
import st from 'utils/shared-translations';

import './credit-card-pay.css';

const CreditCardPay: FC = () => {
  const intl = useIntl();

  const selectedPartnerType = useAppSelector(partnerType);

  const selectedCreditCardList = useAppSelector(creditCardList);
  const selectedDefaultCreditCard = useAppSelector(defaultCreditCard);
  const selectedPaymentMethodsLimit = useAppSelector(paymentMethodLimits);

  const [isCCForm, setCCForm] = useState(false);
  const [currentCreditCard, setCurrentCreditCard] = useState(selectedDefaultCreditCard);

  // To be used on mount
  useEffect(() => {
    if (!selectedCreditCardList.length) {
      Track(PaynowUseCC, { CC: 'new' });
      setCCForm(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const changePaymentOption = (event: any) => {
    const { value } = event.currentTarget;
    const currentCC = selectedCreditCardList.find((card: CreditCard) => card.paymentMethodKey === value);
    if (value === 'add-cc') {
      Track(PaynowUseCC, { CC: 'new' });
      setCCForm(true);
    } else if (currentCC) {
      Track(PaynowUseCC, { CC: 'existing' });
      setCurrentCreditCard(currentCC!);
      setCCForm(false);
    }
  };

  return (
    <>
      <CreditCardHeader />
      <RadioGroup
        aria-label="payment-method-selector"
        className="credit-card-pay"
        value={currentCreditCard.paymentMethodKey || 'add-cc'}
        onChange={changePaymentOption}
      >
        {selectedCreditCardList.map((card: CreditCard) => (
          <RadioButton
            className="credit-card-pay__payment-method"
            value={card.paymentMethodKey}
            key={card.paymentMethodKey}
          >
            <CardInfo cardDetails={card} />
          </RadioButton>
        ))}
        {selectedCreditCardList.length < selectedPaymentMethodsLimit.limits.CREDIT_CARD &&
          selectedPartnerType !== 'PARENT' && (
            <RadioButton className="credit-card-pay__payment-method--add-new" value="add-cc">
              {intl.formatMessage(st['credit.card.pay.add.new.card'])}
              {isCCForm && <CreditCardForm intent={CREDIT_CARD_ADD_PAY} />}
            </RadioButton>
          )}
      </RadioGroup>
      {!isCCForm && !!selectedCreditCardList.length && <BuyNow currentCreditCard={currentCreditCard} />}
    </>
  );
};

export default CreditCardPay;
