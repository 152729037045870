import { FC, useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Button, Checkbox } from '@getgo/chameleon-web-react-wrapper';

import { useAppDispatch, useAppSelector } from 'hooks';
import Cardinal from 'lib/cardinal-songbird';
import { CybersourceFingerprint } from 'lib/cybersource-fingerprint';
import { postProcessRenewal } from 'modules/cog';
import { showErrorSnack } from 'modules/error';
import { renewalDate, sessionId } from 'modules/global-wrapper';
import {
  sessionCurrencyCode,
  sessionProducts,
  sessionQuoteId,
  sessionSuccessRedirectUrl,
  sessionTotalAmount,
  sesssionCancelRedirectUrl,
} from 'modules/session-details';
import Track, { CreditCardDefaultSet, PaynowSaveAndPay, Revenue } from 'modules/tracking';
import {
  addCcResp,
  postTransactionAddCcPay,
  postTransactionAddCcScaPay,
  transactionsCcPrepareResp,
} from 'modules/transactions-cc';
import { postProcessQuote } from 'modules/ucs';
import { CREDIT_CARD, CREDIT_CARD_DEFAULT } from 'utils/constants';
import st from 'utils/shared-translations';

import './credit-card-add-pay-button.css';

interface CreditCardAddPayButtonProps {
  isSubmitting: boolean;
  isFormInvalid: boolean;
  flexFormToken: string;
  formValues: any;
  setFormValues: any;
}

let isSubmit = true;
let isDisabled = false;

const CreditCardAddPayButton: FC<CreditCardAddPayButtonProps> = ({
  isSubmitting,
  isFormInvalid,
  flexFormToken,
  formValues,
  setFormValues,
}) => {
  const intl = useIntl();
  const dispatch = useAppDispatch();

  const [otpJWT, setOtpJWT] = useState('');

  const selectedSessionId = useAppSelector(sessionId);

  const selectedRenewalDate = useAppSelector(renewalDate);

  // Session details selectors
  const selectedSuccessRedirectUrl = useAppSelector(sessionSuccessRedirectUrl);
  const selectedQuoteTotalPrice = useAppSelector(sessionTotalAmount);
  const selectedQuoteKey = useAppSelector(sessionQuoteId);
  const selectedSessionProducts = useAppSelector(sessionProducts);
  const selectedSessionCurrencyCode = useAppSelector(sessionCurrencyCode);
  const selectedSessionCancelUrl = useAppSelector(sesssionCancelRedirectUrl);

  const selectedTransactionPrepareResp = useAppSelector(transactionsCcPrepareResp);
  const selectedAddCreditCardResp = useAppSelector(addCcResp);

  /**
   * Second Payment save API if the OTP is submitted correctly.
   */
  useEffect(() => {
    if (otpJWT && selectedAddCreditCardResp.paymentMethodKey) {
      dispatch(
        postTransactionAddCcScaPay({
          cardinalJwt: otpJWT,
          setAsDefault: formValues[CREDIT_CARD_DEFAULT],
          referenceId: selectedTransactionPrepareResp.referenceId,
          paymentKey: selectedAddCreditCardResp.paymentKey,
          paymentMethodKey: selectedAddCreditCardResp.paymentMethodKey,
        }),
      )
        .unwrap()
        .catch(() => dispatch(showErrorSnack(st['alert.error.general.refreshtryagain'])));
      setOtpJWT('');
    }
  }, [dispatch, formValues, otpJWT, selectedAddCreditCardResp, selectedTransactionPrepareResp]);

  const processQuote = useCallback(() => {
    const data = {
      quoteCode: selectedQuoteKey,
      payload: {
        grossAmount: selectedQuoteTotalPrice,
        sessionId: selectedSessionId,
        paymentType: CREDIT_CARD,
      },
    };
    dispatch(postProcessQuote(data))
      .unwrap()
      .then(() =>
        Track(Revenue, {
          orderList: selectedSessionProducts,
          currencyCode: selectedSessionCurrencyCode,
          purchaseFlow: selectedSessionCancelUrl.includes('subscriptions') ? 'subscription' : 'quote',
          paymentMethod: 'cc',
          quoteId: selectedQuoteKey,
        }),
      );
  }, [
    dispatch,
    selectedQuoteKey,
    selectedQuoteTotalPrice,
    selectedSessionCancelUrl,
    selectedSessionCurrencyCode,
    selectedSessionId,
    selectedSessionProducts,
  ]);

  const processRenwal = useCallback(() => {
    const payload = {
      date: selectedRenewalDate,
      sessionId: selectedSessionId,
      amount: selectedQuoteTotalPrice.toString(),
    };
    dispatch(postProcessRenewal(payload))
      .unwrap()
      .then(() =>
        Track(Revenue, {
          orderList: selectedSessionProducts,
          currencyCode: selectedSessionCurrencyCode,
          purchaseFlow: 'renewals',
          paymentMethod: 'cc',
          quoteId: selectedQuoteKey,
          amount: selectedQuoteTotalPrice,
        }),
      );
  }, [
    dispatch,
    selectedQuoteKey,
    selectedQuoteTotalPrice,
    selectedRenewalDate,
    selectedSessionCurrencyCode,
    selectedSessionId,
    selectedSessionProducts,
  ]);

  /**
   * Based on the response from 1st payment Save API,
   * either show 3DS challenge if payerAuth result is available,
   * else, redirect the user to Payment method page.
   */
  useEffect(() => {
    if (selectedAddCreditCardResp) {
      const { payerAuthEnrollmentResult, paymentMethodKey } = selectedAddCreditCardResp;
      if (payerAuthEnrollmentResult) {
        const cardinalPayload = {
          AcsUrl: payerAuthEnrollmentResult.acsUrl,
          Payload: payerAuthEnrollmentResult.paReq,
        };
        const orderPayload = {
          OrderDetails: {
            TransactionId: payerAuthEnrollmentResult.authenticationTransactionId,
          },
        };
        Cardinal.show3DSChallange(cardinalPayload, orderPayload);
      } else if (paymentMethodKey) {
        selectedRenewalDate ? processRenwal() : processQuote();
      }
    }
  }, [
    processQuote,
    processRenwal,
    selectedAddCreditCardResp,
    selectedRenewalDate,
    selectedSessionId,
    selectedSuccessRedirectUrl,
  ]);

  /**
   * First payment method save API.
   */
  const call1stPaymentSaveAPI = () => {
    dispatch(
      postTransactionAddCcPay({
        fingerPrintSessionId: CybersourceFingerprint.id,
        cardinalJwt: selectedTransactionPrepareResp.tokenString,
        flexResponse: flexFormToken,
        flexKeyId: selectedTransactionPrepareResp.keyId,
        setAsDefault: formValues[CREDIT_CARD_DEFAULT],
        referenceId: selectedTransactionPrepareResp.referenceId,
      }),
    )
      .unwrap()
      .catch(() => dispatch(showErrorSnack(st['alert.error.general.refreshtryagain'])));
  };

  /**
   * After OTP is submitted successfully, store it locally to be used in 2nd payment save API.
   */
  const handleOTPChallenge = (otpResp: any, jwt: string) => {
    if (otpResp.ErrorNumber === 0 && otpResp.ErrorDescription === 'Success') {
      // If user cancels the OTP challenge.
      if (otpResp.Payment?.ExtendedData?.ChallengeCancel === '01') {
        dispatch(showErrorSnack(st['alert.error.general.refreshtryagain']));
        return;
      }
      setOtpJWT(jwt);
      return;
    }
    dispatch(showErrorSnack(st['alert.error.general.refreshtryagain']));
  };

  /**
   * Once Cardinal is successfully triggered, it calls save payment method.
   */
  const triggerCardinal = () => {
    const parsedToken = JSON.parse(atob(flexFormToken.split('.')[1]));
    return Cardinal.trigger(parsedToken.data.number.slice(0, 6))
      .then(call1stPaymentSaveAPI)
      .catch(() => dispatch(showErrorSnack(st['alert.error.general.refreshtryagain'])));
  };

  /**
   * Configure Cardinal then initalizes with sca token.
   * Cardinal can been initlized only once unless the browser is refreshed.
   * This is limitation from Cardinal side, since they don`t have a Cardinal session-destroy method.
   */
  const setupCardinal = () => {
    Cardinal.configure()
      .then(() => {
        // Initialize Cardinal with SCA Token
        Cardinal.initialize({
          referenceId: selectedTransactionPrepareResp.referenceId,
          isScaEnabledForMid: selectedTransactionPrepareResp.isScaEnabledForMid,
          tokenString: selectedTransactionPrepareResp.tokenString,
        });

        // Event listener after the cardinal is initiated. Triggers bin process.
        Cardinal.addEventListener('payments.setupComplete', () => triggerCardinal());

        // Event listener after the OTP is submitted.
        Cardinal.addEventListener('payments.validated', (otpResp, jwt) => handleOTPChallenge(otpResp, jwt));
      })
      .catch(() => dispatch(showErrorSnack(st['alert.error.general.refreshtryagain'])));
  };

  // Once user submits the form
  if (isSubmit && flexFormToken && selectedTransactionPrepareResp) {
    Track(PaynowSaveAndPay, {});
    isSubmit = false;
    isDisabled = true;
    if (selectedTransactionPrepareResp.isScaEnabledForMid) {
      setupCardinal();
    } else {
      call1stPaymentSaveAPI();
    }
  }

  // Set default card handler
  const handleDefaultCard = (event: any) => {
    const { checked } = event.target;
    Track(CreditCardDefaultSet, { checked });

    setFormValues({
      ...formValues,
      [CREDIT_CARD_DEFAULT]: checked,
    });
  };

  return (
    <section className="credit-card-add-pay-button">
      <Checkbox disabled={isSubmitting} onChange={handleDefaultCard}>
        {intl.formatMessage(st['payment.method.set.as.default'])}
      </Checkbox>
      <section>
        <Button variant="primary" type="submit" disabled={isFormInvalid || isDisabled} isLoading={isSubmitting}>
          {intl.formatMessage(st['credit.card.save.pay'])}
        </Button>
      </section>
    </section>
  );
};

export default CreditCardAddPayButton;
