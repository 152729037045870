import { ReactNode } from 'react';
import { IntlShape } from 'react-intl';
import { AmexIcon, DiscoverIcon, MastercardIcon, VisaLightModeIcon } from '@getgo/chameleon-icons/react';

import st from 'utils/shared-translations';

export const getCardInfo = (
  cardType: string,
  intl: IntlShape,
  iconSize = '30px',
): {
  cardType: string;
  ccDigitLength: number;
  cardIcon: ReactNode;
} => {
  let ccText = '',
    ccDigitLength = 16,
    cardIcon = <></>;

  switch (cardType) {
    case 'VISA':
      ccText = intl.formatMessage(st['creditcard.name.visa']);
      cardIcon = <VisaLightModeIcon size={iconSize} />;
      break;
    case 'MASTER_CARD':
    case 'MASTERCARD':
      ccText = intl.formatMessage(st['creditcard.name.mastercard']);
      cardIcon = <MastercardIcon size={iconSize} />;
      break;
    case 'DISCOVER':
      ccText = intl.formatMessage(st['creditcard.name.discover']);
      cardIcon = <DiscoverIcon size={iconSize} />;
      break;
    case 'AMEX':
    case 'AMERICANEXPRESS':
      ccText = intl.formatMessage(st['creditcard.name.amex']);
      ccDigitLength = 15;
      cardIcon = <AmexIcon size={iconSize} />;
      break;
    default:
      ccText = '';
      ccDigitLength = 16;
  }
  return {
    cardType: ccText,
    ccDigitLength,
    cardIcon,
  };
};
