import { FC, useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useLifecycles } from 'react-use';
import { Skeleton, Typography } from '@getgo/chameleon-web-react-wrapper';

import { useAppDispatch, useAppSelector } from 'hooks';
import { FlexMicroformWrapper } from 'lib/cybersource-flex-microform';
import { isCardNumberValid, isCCLoading, setCardNumberValidity } from 'modules/cc-ui';
import { theme } from 'modules/global-wrapper';
import { transactionsCcIsLoading } from 'modules/transactions-cc';
import { CREDIT_CARD_NUMBER } from 'utils/constants';
import { getCardInfo } from 'utils/credit-card-utils';
import st from 'utils/shared-translations';

import './credit-card-number.css';

const FORM_FIELD_CREDIT_CARD_ID = 'credit-card-number-field';

let cardNumberFieldInstance: any = null;

const cardNumberStyle = {
  styles: {
    input: {
      'font-size': '14px',
    },
  },
};

interface CreditCardNumberProps {
  FlexMicroform: FlexMicroformWrapper;
  formValues: any;
  setFormValues: any;
  isMicroformReady: boolean;
}

const CreditCardNumber: FC<CreditCardNumberProps> = ({
  FlexMicroform,
  formValues,
  setFormValues,
  isMicroformReady,
}): JSX.Element => {
  const intl = useIntl();
  const dispatch = useAppDispatch();

  const [isCardNumberErrorMessage, setCardNumberErrorMessage] = useState(false);
  const [isCardNumberFieldFocus, setCardNumberFieldFocus] = useState(false);
  const [cardType, setCardType] = useState('');

  const selectedGlobalTheme = useAppSelector(theme);

  const selectedTransactionCcLoading = useAppSelector(transactionsCcIsLoading);

  const selectedCCIsLoading = useAppSelector(isCCLoading);
  const selectedCardNumberIsValid = useAppSelector(isCardNumberValid);

  /**
   * Mount and Unmount
   * Dispose card instances. Reset Card type.
   */
  useLifecycles(
    () => displayCardType({}),
    () => {
      cardNumberFieldInstance && cardNumberFieldInstance.dispose();
      cardNumberFieldInstance = null;
      displayCardType({});
    },
  );

  /**
   * Handles changes made on card number field
   */
  const cardNumberChangeHandler = useCallback(
    (data) => {
      if (data.empty) {
        setFormValues((prevFormValues) => ({ ...prevFormValues, [CREDIT_CARD_NUMBER]: '' }));
      } else {
        setFormValues((prevFormValues) => ({ ...prevFormValues, [CREDIT_CARD_NUMBER]: 'notEmpty' }));
      }

      displayCardType(data);
      dispatch(setCardNumberValidity(data.valid));
    },
    [setFormValues, dispatch],
  );

  /**
   * Create Flex form instance of credit card number and Add events on it.
   */
  useEffect(() => {
    if (isMicroformReady && !cardNumberFieldInstance) {
      cardNumberFieldInstance = FlexMicroform.createFieldInstance(CREDIT_CARD_NUMBER, cardNumberStyle);

      cardNumberFieldInstance.on('change', (data) => cardNumberChangeHandler(data));
      cardNumberFieldInstance.on('focus', () => setCardNumberFieldFocus(true));
      cardNumberFieldInstance.on('blur', () => {
        setCardNumberErrorMessage(!selectedCardNumberIsValid);
        setCardNumberFieldFocus(false);
      });

      cardNumberFieldInstance.load(`#${FORM_FIELD_CREDIT_CARD_ID}`);
      cardNumberFieldInstance.on('load', () => cardNumberFieldInstance.focus());
    }
  }, [FlexMicroform, cardNumberChangeHandler, selectedCardNumberIsValid, isMicroformReady]);

  /**
   * returns card type to be set.
   */
  const displayCardType = (data) => {
    let cardType = '';
    if (data.card && data.card.length === 1) {
      cardType = data.card[0].name;
      return setCardType(cardType);
    }
    return setCardType(cardType);
  };

  const cardInfo = getCardInfo(cardType?.toUpperCase(), intl);

  const isCardNumberInvalidMessage =
    isCardNumberErrorMessage &&
    !isCardNumberFieldFocus &&
    !selectedCardNumberIsValid &&
    !!formValues[CREDIT_CARD_NUMBER];

  const getCCNumberClass = () => {
    if (isCardNumberInvalidMessage) {
      return 'credit-card-number__error';
    } else if (isCardNumberFieldFocus) {
      return 'credit-card-number__focused';
    }
    return 'credit-card-number__field';
  };

  const isLoading = selectedTransactionCcLoading || selectedCCIsLoading;

  return (
    <section>
      <Typography
        tag="label"
        className="credit-card-number__label"
        variant="caption-medium-strong"
        color="type-color-default"
      >
        {intl.formatMessage(st['creditcardform.field.cardnumber'])}
      </Typography>
      {isLoading ? (
        <Skeleton size="large" variant="rectangle" />
      ) : (
        <div className="credit-card-number__input">
          <input name={CREDIT_CARD_NUMBER} defaultValue={formValues[CREDIT_CARD_NUMBER]} type="text" hidden />
          <div id={FORM_FIELD_CREDIT_CARD_ID} className={getCCNumberClass()} />
          <span
            className={selectedGlobalTheme === 'dark' ? 'credit-card-number__icon--dark' : 'credit-card-number__icon'}
          >
            {cardInfo.cardIcon}
          </span>
        </div>
      )}
      {isCardNumberInvalidMessage && (
        <Typography variant="caption-medium" tag="p" color="type-color-danger">
          {intl.formatMessage(st['creditcardform.cardnumber.validation.errormessage'])}
        </Typography>
      )}
    </section>
  );
};

export default CreditCardNumber;
