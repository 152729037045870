import { defineMessages } from 'react-intl';

const t = defineMessages({
  'alert.error.paymentmethod.general.failure': {
    defaultMessage: 'Sorry, there was a problem saving your payment method details. Try again.',
    id: 'alert.error.paymentmethod.general.failure',
  },
  'alert.error.general.refreshtryagain': {
    defaultMessage: 'Sorry, something went wrong. Refresh your browser and try again.',
    id: 'alert.error.general.refreshtryagain',
  },
  'back.to.checkout': {
    defaultMessage: 'Back to the checkout',
    id: 'back.to.checkout',
  },
  'back.to.previous.page': {
    defaultMessage: 'Back',
    id: 'back.to.previous.page',
  },
  'reference.number': {
    defaultMessage: 'Reference number',
    id: 'reference.number',
  },
  'price.info.recipient': {
    defaultMessage: 'Recipient',
    id: 'price.info.recipient',
  },
  'your.contact.info': {
    defaultMessage: 'Your contact info',
    id: 'your.contact.info',
  },
  'price.info.total': {
    defaultMessage: 'Total',
    id: 'price.info.total',
  },
  'price.info.card': {
    defaultMessage: 'Card',
    id: 'price.info.card',
  },
  'back.to.payment.methods': {
    defaultMessage: 'Back to Payment methods',
    id: 'back.to.payment.methods',
  },
  // CC
  'creditcard.name.amex': {
    defaultMessage: 'Amex',
    id: 'creditcard.name.amex',
  },
  'creditcard.name.discover': {
    defaultMessage: 'Discover',
    id: 'creditcard.name.discover',
  },
  'creditcard.name.mastercard': {
    defaultMessage: 'Mastercard',
    id: 'creditcard.name.mastercard',
  },
  'creditcard.name.visa': {
    defaultMessage: 'Visa',
    id: 'creditcard.name.visa',
  },
  'creditcard.limit.info': {
    defaultMessage: 'You can save a maximum of 2 credit cards. Delete any unused cards to add another.',
    id: 'creditcard.limit.info',
  },
  'creditcardform.field.cardnumber': {
    defaultMessage: 'Card number',
    id: 'creditcardform.field.cardnumber',
  },
  'creditcardform.cardnumber.validation.errormessage': {
    defaultMessage: 'Enter a valid card number',
    id: 'creditcardform.cardnumber.validation.errormessage',
  },
  'creditcardform.cvv.validation.errormessage': {
    defaultMessage: 'Enter a valid CVV',
    id: 'creditcardform.cvv.validation.errormessage',
  },
  'creditcardform.date.validation.general.errormessage': {
    defaultMessage: 'Use the format MM/YYYY to enter the expiration date.',
    id: 'creditcardform.date.validation.general.errormessage',
  },
  'creditcardform.date.validation.past.errormessage': {
    defaultMessage: "The expiration date can't be in the past.",
    id: 'creditcardform.date.validation.past.errormessage',
  },
  'creditcardform.date.validation.invalid.errormessage': {
    defaultMessage: 'The expiration date is invalid. Check your input.',
    id: 'creditcardform.date.validation.invalid.errormessage',
  },
  'creditcardform.paymentdetails.cvv': {
    defaultMessage: 'CVV',
    id: 'creditcardform.paymentdetails.cvv',
  },
  'creditcardform.paymentdetails.cvv.message': {
    defaultMessage: '3-4 digits printed on the back or front of your card',
    id: 'creditcardform.paymentdetails.cvv.message',
  },
  'creditcardform.date.expiration': {
    defaultMessage: 'Expiration date',
    id: 'creditcardform.date.expiration',
  },
  'credit.card.pay.add.new.card': {
    defaultMessage: 'Add new card',
    id: 'credit.card.pay.add.new.card',
  },
  'credit.card.save.pay': {
    defaultMessage: 'Save and pay',
    id: 'credit.card.save.pay',
  },
  'creditcardform.cta.save': {
    defaultMessage: 'Save',
    id: 'creditcardform.cta.save',
  },
  'creditcardform.cta.cancel': {
    defaultMessage: 'Cancel',
    id: 'creditcardform.cta.cancel',
  },
  // Payment
  'pay.now.cta': {
    defaultMessage: 'Pay now',
    id: 'pay.now.cta',
  },
  'payment.tnc': {
    defaultMessage:
      "Pricing valid for new purchases only. Your plan will auto-renew unless cancelled before the next renewal date. By clicking 'Pay now', I agree to the <tos-link>Terms of Service</tos-link>, <privacy-policy>Privacy Policy</privacy-policy>, and <anti-spam>Anti-Spam Policy</anti-spam>.",
    id: 'payment.tnc',
  },
  'payment.method.set.as.default': {
    defaultMessage: 'Set as default payment method',
    id: 'payment.method.set.as.default',
  },
  'payment.methods.default': {
    defaultMessage: 'Default',
    id: 'payment.methods.default',
  },
  'payment.methods.cta.setdefault': {
    defaultMessage: 'Set as default',
    id: 'payment.methods.cta.setdefault',
  },
  // Session
  'session.timer.info': {
    defaultMessage:
      '<bold>Secure transaction in progress</bold>. This session will expire in {minute} min and {second} sec.',
    id: 'session.timer.info',
  },
  'session.timeout.dialog.header': {
    defaultMessage: 'Your session timed out',
    id: 'session.dialog.header',
  },
  'session.timeout.dialog.checkout.message': {
    defaultMessage: 'Your session timed out before your purchase was completed. All your items are still in the cart.',
    id: 'session.timeout.dialog.checkout.message',
  },
  // UCS
  'feedback.success.to.product': {
    defaultMessage: 'To products',
    id: 'feedback.success.to.product',
  },
  'feedback.success.partner.grant.cta': {
    defaultMessage: 'Grant partner access',
    id: 'feedback.success.partner.grant.cta',
  },
  'feedback.success.back.to.subscriptions': {
    defaultMessage: 'Back to subscriptions',
    id: 'feedback.success.back.to.subscriptions',
  },
  'feedback.success.order.purchase.successful': {
    defaultMessage: 'Thank you for your purchase!',
    id: 'feedback.success.order.purchase.successful',
  },
  'feedback.failure.payment.process': {
    defaultMessage: 'Sorry, we couldn’t process your payment.',
    id: 'feedback.failure.payment.process',
  },
  'feedback.failure.payment.info': {
    defaultMessage:
      'Change your payment method and try again, or <link>contact your sales representative</link> for assistance completing your purchase.',
    id: 'feedback.failure.payment.info',
  },
  'feedback.failure.something.went.wrong': {
    defaultMessage: 'Something went wrong',
    id: 'feedback.failure.something.went.wrong',
  },
  'process.quote.loader.patience.message': {
    defaultMessage: "We're processing your payment. This will just take a moment.",
    id: 'process.quote.loader.patience.message',
  },
  'process.quote.loader.morepatience.message': {
    defaultMessage:
      "Sorry, this is taking longer than expected. Don't close your window until your payment has processed.",
    id: 'process.quote.loader.morepatience.message',
  },
});

export default t;
