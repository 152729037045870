import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import {
  daoPaymentMethodsDefaultSetPost,
  daoPaymentMethodsDeletePost,
  daoPaymentMethodsPost,
} from 'dao/payment-methods-dao';
import { Action } from 'types/action';
import {
  CreditCard,
  PaymentMethodDeletePayload,
  PaymentMethodLimits,
  PaymentMethodSetDefaultPayload,
  PaymentMethodsState,
} from 'types/payment-method';
import { Thunk } from 'types/root';

export const postPaymentMethods = createAsyncThunk<PaymentMethodsState, void, Thunk>(
  'paymentMethods/get',
  async (_, thunkAPI) => {
    const state = thunkAPI.getState();
    const {
      globalWrapper: { accountKey, sessionId },
    } = state;
    const response = await daoPaymentMethodsPost(accountKey, sessionId);
    return response as PaymentMethodsState;
  },
);

export const postPaymentMethodsDelete = createAsyncThunk<PaymentMethodsState, PaymentMethodDeletePayload, Thunk>(
  'paymentMethods/delete',
  async (payload, thunkAPI) => {
    const state = thunkAPI.getState();
    const {
      globalWrapper: { accountKey, sessionId },
    } = state;
    const response = await daoPaymentMethodsDeletePost(accountKey, sessionId, payload);
    return response as PaymentMethodsState;
  },
);

export const postPaymentMethodsSetDefault = createAsyncThunk<
  PaymentMethodsState,
  PaymentMethodSetDefaultPayload,
  Thunk
>('paymentMethods/setDefault', async (payload, thunkAPI) => {
  const state = thunkAPI.getState();
  const {
    globalWrapper: { accountKey, sessionId },
  } = state;
  const response = await daoPaymentMethodsDefaultSetPost(accountKey, sessionId, payload);
  return response as PaymentMethodsState;
});

const initialState: PaymentMethodsState = {
  isLoading: false,
  creditCards: [] as CreditCard[],
  paymentMethodLimits: {} as PaymentMethodLimits,
};

const paymentMethodsSlice = createSlice({
  name: 'payment-methods',
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(postPaymentMethods.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(postPaymentMethods.fulfilled, (state, action: Action) => {
        const { creditCards, paymentMethodLimits } = action.payload;
        state.isLoading = false;
        state.creditCards = creditCards;
        state.paymentMethodLimits = paymentMethodLimits;
      })
      .addCase(postPaymentMethods.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export default paymentMethodsSlice.reducer;
