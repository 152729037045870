export const RECEIPIENT = 'GoTo Technologies';
export const CONTACT_PRODUCT_SALES = 'https://www.goto.com/learn-more';

export const DD_SEPA_DETAILS = Object.freeze({
  crditorName: 'LogMeIn Inc.',
  creditorIdentifier: 'GB53ZZZSDDBARC000007495896029',
  paymentType: 'Recurring payment',
  mandateReference: 'LOGMEINIE-ZE23JMSG',
  streetAddress: 'The Reflector, 10 Hanover Quay',
  city: 'Dublin 2',
  postalCode: 'D02R573',
  Country: 'Republic of Ireland',
});

// Form
export const DD_FORM_STEP_FORM = 'form';
export const DD_FORM_STEP_MANDATE = 'mandate';

// Flow type
export const ADDRESS_PAYMENT_FLOW_TYPE = Object.freeze({ cc: 'cc', dd: 'dd', address: 'address' });
export const supportedLocales = ['de_DE', 'en_US', 'es_ES', 'fr_FR', 'it_IT', 'ja_JP', 'ko_KR', 'pt_BR', 'zh_CN'];

// Credit Card
export const CREDIT_CARD = 'CREDIT_CARD';
export const CREDIT_CARD_SAVE = 'CreditCardSave';
export const CREDIT_CARD_ADD_PAY = 'CreditCardAddPay';
export const CREDIT_CARD_NUMBER = 'number';
export const CREDIT_CARD_CVV = 'securityCode';
export const CREDIT_CARD_EXP = 'month-year';
export const CREDIT_CARD_DEFAULT = 'creditCardDefault';

export const CENTRAL_PRODUCT_FAMILY = 'Central';
export const PRO_PRODUCT_FAMILY = 'Pro';
export const HAMACHI_PRODUCT_FAMILY = 'Hamachi';

export const classicProductFamilyKey: ReadonlyArray<string> = [
  CENTRAL_PRODUCT_FAMILY,
  PRO_PRODUCT_FAMILY,
  HAMACHI_PRODUCT_FAMILY,
];
