import { FC, useEffect, useMemo, useState } from 'react';
import { IntlProvider } from 'react-intl';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { useAsync } from 'react-use';
import { ThemeProvider } from '@getgo/chameleon-web-react-wrapper';

import Core from 'components/core';
import config from 'config';
import Amplitude from 'lib/amplitude';
import AuthProvider from 'lib/auth-provider';
import {
  hideGlobalLoading,
  setGlobalAccountKey,
  setGlobalLocale,
  setGlobalSessionId,
  setGlobalTheme,
  setPartnerType,
  setRenewalDate,
} from 'modules/global-wrapper';
import store from 'store';
import { Theme } from 'types/global-wrapper';
import { supportedLocales } from 'utils/constants';

import '@getgo/chameleon-web/components/registerAll';

const App: FC = () => {
  const { pathname, search } = window.location;
  const serachParams = useMemo(() => new URLSearchParams(search), [search]);
  const pathNameList = pathname.split('/');

  const [locale, setLocale] = useState('en_US');
  const [lang, setLang] = useState('en');
  const [theme, setTheme] = useState<Theme>('light');
  const [message, setMessage] = useState({} as Record<string, string>);

  useEffect(() => {
    // locale
    let locale = serachParams.get('locale') || 'en_US';
    locale = supportedLocales.includes(locale) ? locale : 'en_US';
    setLocale(locale);
    store.dispatch(setGlobalLocale(locale));

    // theme
    const theme = (serachParams.get('theme') as Theme) || 'light';
    setTheme(theme);
    store.dispatch(setGlobalTheme(theme));

    //partner type
    const partnerType = serachParams.get('partnerType') || '';
    store.dispatch(setPartnerType(partnerType));

    // renewal date
    const renewalDate = serachParams.get('renewalDate') || '';
    store.dispatch(setRenewalDate(renewalDate));

    // accountKey
    const accountKey = pathNameList[1] || '';
    store.dispatch(setGlobalAccountKey(accountKey));

    // sessionId
    const sessionId = pathNameList[2] || '';
    store.dispatch(setGlobalSessionId(sessionId));

    // Initialize Amplitude
    accountKey && Amplitude.initialize(accountKey);

    // Wait for the global state to be set.
    store.dispatch(hideGlobalLoading());
  }, [pathNameList, pathname, serachParams]);

  // Load translations
  useAsync(async () => {
    const language = locale?.split('_')?.[0] || 'en';
    setLang(language);

    const message = await import(`translations/${locale}.json`);
    setMessage(message.default);
  }, [locale]);

  // Clear auth token from sessionStorage when user moves out of OnePay
  useEffect(() => {
    const onBeforeUnload = () => {
      const tokenName = config.AUTH_TOKEN_STORAGE_KEY;
      sessionStorage.removeItem(tokenName);
    };

    window.addEventListener('beforeunload', onBeforeUnload);

    return () => window.removeEventListener('beforeunload', onBeforeUnload);
  }, []);

  return (
    <AuthProvider>
      <Provider store={store}>
        <IntlProvider locale={lang} messages={message}>
          <ThemeProvider className="app__theme-provider" theme={theme} skin="gotoadmin" rebranding2021>
            <BrowserRouter>
              <Core />
            </BrowserRouter>
          </ThemeProvider>
        </IntlProvider>
      </Provider>
    </AuthProvider>
  );
};

export default App;
