import { FC, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { ChevronLeftOutlinedIcon, GotoAdminPartnerFaviconIcon } from '@getgo/chameleon-icons/react';
import { Button, GotoLogo } from '@getgo/chameleon-web-react-wrapper';

import SessionExpiredModal from 'components/session-expired-modal';
import SessionTimer from 'components/session-timer';
import { useAppSelector } from 'hooks';
import { accountKey } from 'modules/global-wrapper';
import { isSessionExpired, sesssionCancelRedirectUrl } from 'modules/session-details';
import Track, { PaymentMethodBackToG2A, PaymentMethodLanding } from 'modules/tracking';
import { CREDIT_CARD } from 'utils/constants';
import st from 'utils/shared-translations';
import CreditCardMethod from './credit-card-method';

import './payment-methods.css';

interface PaymentMethodsProps {
  paymentMethodType: string;
}

const PaymentMethods: FC<PaymentMethodsProps> = ({ paymentMethodType }) => {
  const intl = useIntl();

  const selectedAccountKey = useAppSelector(accountKey);
  const selectedSessionIsExpired = useAppSelector(isSessionExpired);
  const selectedCancelRedirectUrl = useAppSelector(sesssionCancelRedirectUrl);

  useEffect(() => {
    if (selectedAccountKey) {
      Track(PaymentMethodLanding, {});
    }
  }, [selectedAccountKey]);

  const redirectToPaymentMethod = () => {
    Track(PaymentMethodBackToG2A, {});
    window.location.assign(selectedCancelRedirectUrl);
  };

  return (
    <div className="payment-methods">
      {selectedSessionIsExpired && (
        <SessionExpiredModal redirectType="PaymentMethod" redirectMethod={redirectToPaymentMethod} />
      )}
      <nav className="payment-methods__nav">
        <Button
          size="medium"
          variant="neutral"
          leadingIcon={<ChevronLeftOutlinedIcon />}
          onClick={redirectToPaymentMethod}
        >
          {intl.formatMessage(st['back.to.payment.methods'])}
        </Button>
      </nav>
      <header className="payment-methods__header">
        <SessionTimer />
        <GotoLogo>
          <GotoAdminPartnerFaviconIcon />
        </GotoLogo>
      </header>
      <main className="payment-methods__main">
        {
          {
            [CREDIT_CARD]: <CreditCardMethod />,
          }[paymentMethodType]
        }
      </main>
    </div>
  );
};

export default PaymentMethods;
