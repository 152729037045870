import { FC, useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Button } from '@getgo/chameleon-web-react-wrapper';

import { useAppDispatch, useAppSelector } from 'hooks';
import Cardinal from 'lib/cardinal-songbird';
import { CybersourceFingerprint } from 'lib/cybersource-fingerprint';
import { postProcessRenewal } from 'modules/cog';
import { isProcessRenewalLoading } from 'modules/cog/cog-selector';
import { showErrorSnack } from 'modules/error';
import { renewalDate, sessionId } from 'modules/global-wrapper';
import {
  sessionCurrencyCode,
  sessionProducts,
  sessionQuoteId,
  sessionSuccessRedirectUrl,
  sessionTotalAmount,
  sesssionCancelRedirectUrl,
} from 'modules/session-details';
import Track, { PaynowCTA, Revenue } from 'modules/tracking';
import {
  postTransactionPrepare,
  postTransactionUseCcPay,
  postTransactionUseCcScaPay,
  transactionsCcIsLoading,
  transactionsCcPrepareResp,
  useCcPayResp,
} from 'modules/transactions-cc';
import { postProcessQuote } from 'modules/ucs';
import { isProcessQuoteLoading } from 'modules/ucs/ucs-selector';
import { CreditCard } from 'types/payment-method';
import { CREDIT_CARD } from 'utils/constants';
import st from 'utils/shared-translations';

interface BuyNowProps {
  currentCreditCard: CreditCard;
}

let isDisabled = false;

const BuyNow: FC<BuyNowProps> = ({ currentCreditCard }): JSX.Element => {
  const intl = useIntl();
  const dispatch = useAppDispatch();

  const [otpJWT, setOtpJWT] = useState('');
  const [isComponentLoading, setComponentLoading] = useState(false);

  const selectedSessionId = useAppSelector(sessionId);
  const selectedQuoteProcessIsLoading = useAppSelector(isProcessQuoteLoading);
  const selectedProcessRenewalIsLoading = useAppSelector(isProcessRenewalLoading);
  const selectedRenewalDate = useAppSelector(renewalDate);

  // Session details selectors
  const selectedSuccessRedirectUrl = useAppSelector(sessionSuccessRedirectUrl);
  const selectedQuoteTotalPrice = useAppSelector(sessionTotalAmount);
  const selectedQuoteKey = useAppSelector(sessionQuoteId);
  const selectedSessionProducts = useAppSelector(sessionProducts);
  const selectedSessionCurrencyCode = useAppSelector(sessionCurrencyCode);
  const selectedSessionCancelUrl = useAppSelector(sesssionCancelRedirectUrl);

  // Transaction selectors
  const selectedTransactionCcLoading = useAppSelector(transactionsCcIsLoading);
  const selectedTransactionPrepareResp = useAppSelector(transactionsCcPrepareResp);
  const selectedTransactionUseCcResp = useAppSelector(useCcPayResp);

  // Call prepare transaction if the selected credit card is SCA enabled
  useEffect(() => {
    if (currentCreditCard.isScaEnabled) {
      dispatch(postTransactionPrepare({ paymentMethodKey: currentCreditCard.paymentMethodKey }))
        .unwrap()
        .catch(() => {
          setComponentLoading(false);
          dispatch(showErrorSnack(st['alert.error.general.refreshtryagain']));
        });
    }
  }, [currentCreditCard.isScaEnabled, currentCreditCard.paymentMethodKey, dispatch]);

  /**
   * Second Payment save API if the OTP is submitted correctly.
   */
  useEffect(() => {
    if (otpJWT && selectedTransactionUseCcResp.paymentKey) {
      dispatch(
        postTransactionUseCcScaPay({
          fingerPrintSessionId: CybersourceFingerprint.id,
          cardinalJWT: otpJWT,
          referenceId: selectedTransactionPrepareResp.referenceId,
          paymentKey: selectedTransactionUseCcResp.paymentKey,
          paymentMethodKey: selectedTransactionUseCcResp.paymentMethodKey,
        }),
      )
        .unwrap()
        .catch(() => {
          setComponentLoading(false);
          dispatch(showErrorSnack(st['alert.error.general.refreshtryagain']));
        });
      setOtpJWT('');
    }
  }, [dispatch, otpJWT, selectedTransactionPrepareResp.referenceId, selectedTransactionUseCcResp]);

  const processQuote = useCallback(() => {
    const data = {
      quoteCode: selectedQuoteKey,
      payload: {
        grossAmount: selectedQuoteTotalPrice,
        sessionId: selectedSessionId,
        paymentType: CREDIT_CARD, // should be dynamic once we integrate other payment methods
      },
    };
    dispatch(postProcessQuote(data))
      .unwrap()
      .then(() =>
        Track(Revenue, {
          orderList: selectedSessionProducts,
          currencyCode: selectedSessionCurrencyCode,
          purchaseFlow: selectedSessionCancelUrl.includes('subscriptions') ? 'subscription' : 'quote',
          paymentMethod: 'cc',
          quoteId: selectedQuoteKey,
          amount: selectedQuoteTotalPrice,
        }),
      );
  }, [
    dispatch,
    selectedQuoteKey,
    selectedQuoteTotalPrice,
    selectedSessionCancelUrl,
    selectedSessionCurrencyCode,
    selectedSessionId,
    selectedSessionProducts,
  ]);

  const processRenwal = useCallback(() => {
    const payload = {
      date: selectedRenewalDate,
      sessionId: selectedSessionId,
      amount: selectedQuoteTotalPrice.toString(),
    };
    dispatch(postProcessRenewal(payload))
      .unwrap()
      .then(() =>
        Track(Revenue, {
          orderList: selectedSessionProducts,
          currencyCode: selectedSessionCurrencyCode,
          purchaseFlow: 'renewals',
          paymentMethod: 'cc',
          quoteId: selectedQuoteKey,
          amount: selectedQuoteTotalPrice,
        }),
      );
  }, [
    dispatch,
    selectedQuoteKey,
    selectedQuoteTotalPrice,
    selectedRenewalDate,
    selectedSessionCurrencyCode,
    selectedSessionId,
    selectedSessionProducts,
  ]);

  /**
   * Based on the response from 1st payment Save API,
   * either show 3DS challenge if payerAuth result is available,
   * else, redirect the user to Payment method page.
   */
  useEffect(() => {
    if (selectedTransactionUseCcResp) {
      const { payerAuthEnrollmentResult, paymentMethodKey } = selectedTransactionUseCcResp;
      if (payerAuthEnrollmentResult) {
        const cardinalPayload = {
          AcsUrl: payerAuthEnrollmentResult.acsUrl,
          Payload: payerAuthEnrollmentResult.paReq,
        };
        const orderPayload = {
          OrderDetails: {
            TransactionId: payerAuthEnrollmentResult.authenticationTransactionId,
          },
        };
        Cardinal.show3DSChallange(cardinalPayload, orderPayload);
      } else if (paymentMethodKey) {
        selectedRenewalDate ? processRenwal() : processQuote();
      }
    }
  }, [
    selectedTransactionUseCcResp,
    selectedSuccessRedirectUrl,
    selectedSessionId,
    selectedRenewalDate,
    processQuote,
    processRenwal,
  ]);

  /**
   * After OTP is submitted successfully, store it locally to be used in 2nd payment save API.
   */
  const handleOTPChallenge = (otpResp: any, jwt: string) => {
    if (otpResp.ErrorNumber === 0 && otpResp.ErrorDescription === 'Success') {
      // If user cancels the OTP challenge.
      if (otpResp.Payment?.ExtendedData?.ChallengeCancel === '01') {
        setComponentLoading(false);
        dispatch(showErrorSnack(st['alert.error.general.refreshtryagain']));
        return;
      }
      setOtpJWT(jwt);
      return;
    }
    dispatch(showErrorSnack(st['alert.error.general.refreshtryagain']));
    setComponentLoading(false);
  };

  // Call 1st useCCPay API on click of Buy Now
  const payUsingCreditCard = () => {
    dispatch(
      postTransactionUseCcPay({
        fingerPrintSessionId: CybersourceFingerprint.id,
        referenceId: selectedTransactionPrepareResp.referenceId,
        paymentMethodKey: currentCreditCard.paymentMethodKey,
      }),
    )
      .unwrap()
      .catch(() => {
        dispatch(showErrorSnack(st['alert.error.general.refreshtryagain']));
        setComponentLoading(false);
      });
  };

  /**
   * Configure Cardinal then initalizes with sca token.
   * Cardinal can been initlized only once unless the browser is refreshed.
   * This is limitation from Cardinal side, since they don`t have a Cardinal session-destroy method.
   */
  const setupCardinal = () => {
    Cardinal.configure()
      .then(() => {
        // Initialize Cardinal with SCA Token
        Cardinal.initialize({
          referenceId: selectedTransactionPrepareResp.referenceId,
          isScaEnabledForMid: selectedTransactionPrepareResp.isScaEnabledForMid,
          tokenString: selectedTransactionPrepareResp.tokenString,
        });

        Cardinal.addEventListener('payments.setupComplete', () => payUsingCreditCard());

        // Event listener after the OTP is submitted.
        Cardinal.addEventListener('payments.validated', (otpResp, jwt) => handleOTPChallenge(otpResp, jwt));
      })
      .catch(() => {
        dispatch(showErrorSnack(st['alert.error.general.refreshtryagain']));
        setComponentLoading(false);
      });
  };

  // Pay now button click handler
  const payNow = () => {
    isDisabled = true;
    Track(PaynowCTA, {});
    setComponentLoading(true);
    if (currentCreditCard.isScaEnabled) {
      setupCardinal();
    } else {
      payUsingCreditCard();
    }
  };

  return (
    <Button
      isLoading={
        isComponentLoading ||
        selectedTransactionCcLoading ||
        selectedQuoteProcessIsLoading ||
        selectedProcessRenewalIsLoading
      }
      disabled={isDisabled}
      className="card-info__pay-now"
      size="large"
      fullWidth
      onClick={payNow}
    >
      {intl.formatMessage(st['pay.now.cta'])}
    </Button>
  );
};

export default BuyNow;
